import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { WalletService, Account } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-wallet-info',
  templateUrl: './wallet-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./wallet-info.component.scss']
})
export class WalletInfoComponent implements OnInit, OnDestroy {

  address: string;
  walletName: string;
  //$account: Observable<Account> = new Observable<Account>();

  private _accountSub = new BehaviorSubject<Account>(null);
  account$: Observable<Account>;
  subscription: Subscription;
  currentAccount: Account;
  num$: Observable<number>;

  loading: boolean = true;
  notConnected: boolean = false;
  walletConnected: boolean = false;

  constructor(
    public walletService: WalletService
  ) { }

  ngOnInit(): void {
    // Observable 
    this.account$ = this.walletService.getCurrentAccount();

    this.walletService.accountChanged.subscribe((event) => {
      console.log('loading', event);
      this.loading = true;
      this.walletConnected = false;

    });

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getAccount(): Observable<Account> {
    return new Observable<Account>((observer) => {
      console.log('get account');
    });
  }

  connectWallet(){
    //alert('wtf');
    this.walletService.enable();
  }

  loadCurrentUser(): void {
    this.loading = true;
    this.walletService.getCurrentUser().subscribe((address) => {
      if (address.length > 0) {
        this.address = address;
        this.walletConnected = true;
      }
      else {
        this.address = "Connect";
        this.walletConnected = false;
      }
      this.loading = false;
    });
  }
}
