import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ethers, providers } from 'ethers';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { PistolData } from '../models/pistolData';
import { MetamaskWeb3Provider } from '../tokens';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  private _provider: providers.Web3Provider;
  private _signer: providers.JsonRpcSigner;

  private _currentAddress: string = '';
  private _currentEnsName: string = '';
  private _walletConnected: boolean = false;
  private abi = require('../../assets/ERC20.json').abi;
  private pistolAddress = '0xbbBBBBB5AA847A2003fbC6b5C16DF0Bd1E725f61';

  $account: BehaviorSubject<Account> = new BehaviorSubject<Account>(null);
  $accountStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  accountChanged: EventEmitter<string> = new EventEmitter<string>();

  $walletAddress: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  $walletName: Subject<string> = new Subject<string>();

  constructor(
    @Inject(MetamaskWeb3Provider) private web3Provider: providers.ExternalProvider
  ) {
    
    
    console.log('WalletService injected');    
    window['ethereum'].on('accountsChanged', (accounts) => {
      console.log('Account changed', accounts[0]);
      if (this._currentAddress != '' && accounts.length > 0) {
        window.location.reload();
      }
    });

   }

   public enable(): void {
     window['ethereum'].enable();
    //  this.web3Provider.request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {}} ] }).then((res) => {
    //    console.log('request permissions', res);
    //  });
   }

   public disconnect(): void {
     this.web3Provider.request({ method: 'wallet_requestPermissions' });
   }

  // setProvider(provider: providers.Web3Provider): void {
  //   this._provider = provider;
  // }

  getCurrentUser(waitForEns: boolean = false): Observable<string> {
    return new Observable((observer: Observer<string>)=>{
      this.web3Provider.request({ method: 'eth_accounts' }).then((accounts) => {
        this._currentAddress = accounts[0];

        if (accounts.length > 0) {
          this._provider = new ethers.providers.Web3Provider(window['ethereum']);

          console.log('web3 accounts: ', accounts);
          var signer = this._provider.getSigner();
          console.log('signer: ', signer);
          this._signer = signer;

          this._signer.getAddress().then((address) => {
            this.setAddress(address);
  
            console.log('Address', address);
            observer.next(address);
            observer.complete();
          });    
        }
      });
    });

  }

  public getTotalSupply(): Observable<number> {
    return new Observable<number>((observer)=>{
      this._provider = new ethers.providers.Web3Provider(window['ethereum']);
      const signer = this._provider.getSigner();
      const contract = new ethers.Contract(this.pistolAddress, this.abi, signer);
      console.log(contract);
      contract.totalSupply().then((res)=>{
        let totalSupply = res;
        console.log(`Got total supply ${totalSupply.toString()}`)
        observer.next(totalSupply);
        observer.complete();
      },(err)=> {
        observer.error(err);
        observer.complete();
      });  
    });
}

public getPistolData(): Observable<PistolData> {
  return new Observable<PistolData>((observer)=>{
    this._provider = new ethers.providers.Web3Provider(window['ethereum']);
    const signer = this._provider.getSigner();
    const contract = new ethers.Contract(this.pistolAddress, this.abi, signer);
    console.log(contract);
    contract.totalSupply().then((totalSupply)=>{
      console.log(`Got total supply ${(totalSupply / 100000000000000000).toString()}`)
      contract.balanceOf(this._currentAddress).then((userBalance)=>{
        console.log(`Got user's balance: ${(userBalance / 100000000000000000).toString()}`)

        observer.next({ totalSupply: (totalSupply), userBalance: (userBalance) });
        observer.complete();
      },(err)=> {
        observer.error(err);
        observer.complete();
      });  
    },(err)=> {
      observer.error(err);
      observer.complete();
    });  
  });
}

getCurrentAccount(): Observable<Account> {
    return new Observable<Account>((observer) => {
      this.web3Provider.request({ method: 'eth_accounts' }).then((accounts) => {

        if (accounts.length > 0) {
          this._provider = new ethers.providers.Web3Provider(window['ethereum']);

          console.log('web3 accounts: ', accounts);
          var signer = this._provider.getSigner();
          console.log('signer: ', signer);
          this._signer = signer;

          this._signer.getAddress().then((address) => {
            this.setAddress(address);
            console.log(this._provider);
            this._provider.getNetwork().then((network) => { 
              console.log(network);
              if (network.chainId != 1) {
                  // Not mainnet, don't do ENS lookup
                  this.$account.next({ 
                    connected: true,
                    address: address
                  });
                  observer.next({ 
                    connected: true,
                    address: address
                  });
                  observer.complete();

                } 
                else {
                  this._provider.lookupAddress(address).then((res)=>{
                    this.setEnsName(res);
                    this.$account.next({ 
                      connected: true,
                      address: address,
                      name: res
                    });
                    observer.next({ 
                      connected: true,
                      address: address,
                      name: res
                    });
                    observer.complete();
                  });
                }

              }, (err) => {
                console.log(err);
              });
          });    
        } else {
          console.log('No accounts');
          observer.next({ connected: false });
          observer.complete();
          }
      }, (err)=> {
        console.log('Error');
        observer.next(null);
        observer.complete();
      });

    });
  }

  // private _getSigner() {
  //   var signer = this._provider.getSigner();
  //   console.log('Signer', signer);

  //   this._signer = signer;
  //   return signer;

  // }

  setAddress(address: string): void {

    this._currentAddress = address;
    //this.$account.next(address);
    console.log('Next Address:', address);
    //this.$walletAddress.next(address);

//     this._provider.lookupAddress(address).then((res)=>{
//       console.log('ENS', res);
//       this.setEnsName(res);
//       this.$account.next({ 
//         connected: true,
//         address: address, 
//         name: res 
// });
//     });

  }

  setEnsName(name: string): void {
    this.$walletName.next(name);
  }

  signMessage(message: string): void {
    var signer = new ethers.providers.Web3Provider(window['ethereum']).getSigner();
    //var message = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
    console.log('Signer:', signer);
    // This array representation is 32 bytes long
    //var messageBytes = ethers.utils.arrayify(message);
    // Uint8Array [ 221, 242, 82, 173, 27, 226, 200, 155, 105, 194, 176, 104, 252, 55, 141, 170, 149, 43, 167, 241, 99, 196, 161, 22, 40, 245, 90, 77, 245, 35, 179, 239 ]

    // To sign a hash, you most often want to sign the bytes
    this._signer.signMessage(message).then((signature)=>{
      console.log('Signature', signature);
    }).catch((err)=>{
      console.log('User declined to sign');
    });

  }

  
}

export class Account {
  address?: string;
  name?: string;
  connected: boolean;
}