import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getDefaultProvider, providers } from 'ethers';
import { WalletInfoComponent } from './components/wallet-info/wallet-info.component';

export const PROVIDER = new InjectionToken<providers.BaseProvider>('Ethereum Provider', {
  providedIn: 'root',
  factory: () => getDefaultProvider("mainnet", ["0BHcr9AOFHrNyCPF6UsnXrm-r-PUnmbJ"])
});

@NgModule({
  declarations: [
    AppComponent,
    WalletInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
