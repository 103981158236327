import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MetamaskWeb3Provider } from './tokens';
import { getDefaultProvider, Signer, providers, ethers} from 'ethers';
import { WalletService } from './services/wallet.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ethers-poc';
  walletConnected: boolean = false;
  userBalance: number = 0;
  totalSupply: number = 0;

  signMessage = () => this.walletService.signMessage('Message goes here');

  constructor(
    public walletService: WalletService
  ){
  }

  ngOnInit() {
    this.walletService.$account.subscribe((res)=>{
      //console.log('account updated', res);
      this.getPistolData();
    });
  }

  public getPistolData() { 
    this.walletService.getPistolData().subscribe((res)=>{
      this.totalSupply = res.totalSupply;
      this.userBalance = res.userBalance;
    }); 
  }
  // public getUserBalance() { 
  //   this.walletService.getUserBalance().subscribe((res)=>{
  //     this.userBalance = res;
  //   }); 
  
  // }

}
