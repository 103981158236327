
<button (click)="connectWallet()">
    <div *ngIf="account$ | async as account; else isLoading">
        <span *ngIf="account.name">{{account.name}}</span>
        <span *ngIf="!account.name">{{account.address}}</span>
        <span *ngIf="account.connected == false">Connect</span>
    </div>
    <ng-template #isLoading>
        Loading... 
    </ng-template>
</button>
